import React from 'react'
import Plx from 'react-plx'
import VizSensor from 'react-visibility-sensor';
import SEO from '../components/seo'

import { Helmet } from "react-helmet"
import Carousel from 'nuka-carousel'
import MouseButton from '../components/mouseButton'

//Stagewest Images
import PortSWLe from "../images/cep_portfolio_sw_le.jpg"
import PortSWSidelines from "../images/cep_portfolio_sw_sidelines.jpg"
import PortSWPeaks from "../images/cep_portfolio_sw_peaks.jpg"
import PortSWRidge from "../images/cep_portfolio_sw_ridge.jpg"
import PortSWFelt from "../images/cep_portfolio_sw_felt.jpg"
import PortSWWeb from "../images/cep_portfolio_sw_web.jpg"
import PortSWCampaign1 from "../images/cep_portfolio_sw_campaign1.jpg"
import PortSWCampaign2 from "../images/cep_portfolio_sw_campaign2.jpg"
import PortSWCampaign3 from "../images/cep_portfolio_sw_campaign3.jpg"
import PortSWCampaign4 from "../images/cep_portfolio_sw_campaign4.jpg"
import PortSWCampaign5 from "../images/cep_portfolio_sw_campaign5.jpg"
import PortSWCampaign6 from "../images/cep_portfolio_sw_campaign6.jpg"
import PortSWCampaign7 from "../images/cep_portfolio_sw_campaign7.jpg"
import PortSWCampaign8 from "../images/cep_portfolio_sw_campaign8.jpg"

//Videos
import PortSLGVideo from "../videos/Sequence_SLG_1.5_720.mp4"
import PortBJVideo from "../videos/Sequence_BJ_1.5_720.mp4"
import PortGNWVideo from "../videos/Sequence_GNW_1.5_720.mp4"
import PortSBVideo from "../videos/Sequence_SB_1.5.mp4"
import PortStreetVideo from "../videos/Sequence_SC_1.5.mp4"
import PortSKVideo from "../videos/Sequence_SK_1.5.mp4"
import PortCWVideo from "../videos/Sequence_CW_1.5.mp4"
import PortSFVideo from "../videos/Sequence_SFAON_1.5.mp4"
import PortCBBVideo from "../videos/Sequence_CBB.mp4"
import PortHFHVideo from "../videos/Sequence_HFH_1.5.mp4"
import HomepageVideo from "../videos/Sequence_Homepage.mp4"
//import HomepageVideo960 from "../videos/Sequence_Homepage_960.mp4"


//Posters
import PortSLGPoster from "../images/Sutherland_Poster.png"
import PortBJPoster from "../images/BennettJones_Poster.png"
import PortGNWPoster from "../images/Genworth_Poster.png"
import PortSBPoster from "../images/Starbucks2_Poster.png"
import PortStreetPoster from "../images/StreetCapital_Poster.png"
import PortSKPoster from "../images/SicKids_Poster.png"
import PortCWPoster from "../images/Chartwell_Poster.png"
import PortCBBPoster from "../images/CasselsBrock_Poster.png"
import PortSFPoster from "../images/Aon_Poster.png"
import PortHFHPoster from "../images/Habitat_Poster.png"

const SLIDER_SPEED= 1000
const SLIDER_AUTOPLAYINTERVAL = 3000	
const SLIDES_STAGEWEST  =[ 
  {path: PortSWWeb, alt:"Website"},
  {path: PortSWCampaign1, alt:"Campaign"},
  {path: PortSWFelt, alt:"Casino Felt"},
  {path: PortSWRidge, alt:"Ridge"},
  {path: PortSWPeaks, alt:"Peaks"},
  {path: PortSWSidelines, alt:"Sidelines"},
  {path: PortSWLe, alt:"Loyalty Express"},
  {path: PortSWCampaign2, alt:"Campaign"},
  {path: PortSWCampaign3, alt:"Campaign"},
  {path: PortSWCampaign4, alt:"Campaign"},
  {path: PortSWCampaign5, alt:"Campaign"},
  {path: PortSWCampaign6, alt:"Campaign"},
  {path: PortSWCampaign7, alt:"Campaign"},                        
  {path: PortSWCampaign8, alt:"Campaign"}
]

const sliderParallaxData = [
  {
    start: '.SecondBlock',
    startOffset:'99vh',
    duration: '1vh',
    properties: [
      {
        startValue: 100,
        endValue: 0,
        unit: 'vh',
        property: 'height'
      }
    ]
  }
]


const VideoBlock100BParallaxData = [
  {
    start: 'self',
    startOffset: '15vh',
    duration: '40vh',
    properties: [
      {
        startValue: 1.25,
        endValue: 1,
        property: 'scale',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },

    ],
  },
]

export default class ParallaxPage extends React.Component {
  constructor(props) {
    super(props)
     //Video
    this.PortGnwVideoRef = React.createRef()
    this.PortSFVideoRef = React.createRef()
    this.PortBJVideoRef = React.createRef()
    this.PortCWVideoRef = React.createRef()
    this.PortStreetVideoRef = React.createRef()
    this.PortSBVideoRef = React.createRef()
    this.PortCBBVideoRef = React.createRef()
    this.PortSKVideoRef = React.createRef()
    this.PortHFHVideoRef = React.createRef()
    this.BannerVideoRef = React.createRef()
  }

  componentDidMount() {
  }
  
  render() {
    return (
    <>
        <SEO title="Work" description="Our Work"/>
        <Helmet>
          <title>Work</title>
        </Helmet>    
         {/*Slider Block*/}
         <div className="ContentBlock Centered SliderBlock">
         <VizSensor 
              partialVisibility={true} 
              onChange={(isVisible) => {
                isVisible ? this.BannerVideoRef.current.play() : this.BannerVideoRef.current.pause()
              }}
            >           
         <Plx parallaxData={ sliderParallaxData } className="SliderBlockWrapper">
            <div className="TopBanner">
                    <div>
                        <video ref={this.BannerVideoRef} autoPlay loop muted playsInline preload="metadata">
                          <source src={HomepageVideo}/>
                          {/*<source src={HomepageVideo960} media="all and (max-width: 960px)"/>*/}
                        </video>
                        <div className="ImageOverlay"></div>
                       <h4><strong>Change happens everywhere.</strong><br/>Get yours here.</h4>
                    </div>
            </div>
          </Plx>
          </VizSensor>
          <MouseButton></MouseButton>
        </div>
        
        {/*Sutherland  - Video*/}
        <div className="ContentBlock VideoBlock100B SecondBlock" id="SecondBlock">
        <div className="VideoBlockVideo">
            <VizSensor 
              partialVisibility={true} 
              onChange={(isVisible) => {
                isVisible ? this.PortSFVideoRef.current.play() : this.PortSFVideoRef.current.pause()
              }}
            >
            <Plx parallaxData={ VideoBlock100BParallaxData }  className="VideoBlockVideoWrapper DisablePlxMobile">
            <video ref={this.PortSFVideoRef} autoPlay controls controlsList="nofullscreen nodownload" muted playsInline preload="metadata" poster={PortSLGPoster}><source src={PortSLGVideo} type="video/mp4"/></video>
            </Plx> 
            </VizSensor>            
        </div>
              <div className="VideoBlockParagraph">        
              <div className="VideoBlockText">        
              <h3>Sutherland Global</h3>
              <p>Digital Transformation + Process Automation</p>
              </div>
              </div>              

        </div>

        {/*AON - Video*/}
        <div className="ContentBlock VideoBlock100B">
        <div className="VideoBlockVideo">
            <VizSensor 
              partialVisibility={true} 
              onChange={(isVisible) => {
                isVisible ? this.PortSFVideoRef.current.play() : this.PortSFVideoRef.current.pause()
              }}
            >
            <Plx parallaxData={ VideoBlock100BParallaxData }  className="VideoBlockVideoWrapper DisablePlxMobile">
            <video ref={this.PortSFVideoRef} autoPlay controls controlsList="nofullscreen nodownload" muted playsInline preload="metadata" poster={PortSFPoster}><source src={PortSFVideo} type="video/mp4"/></video>
            </Plx> 
            </VizSensor>            
        </div>
              <div className="VideoBlockParagraph">        
              <div className="VideoBlockText">        
              <h3>AON</h3>
              <p>Cybersecurity</p>
              </div>
              </div>              

        </div>

        {/*Video Block 100*/}
        <div className="ContentBlock VideoBlock100B">
            <div className="VideoBlockVideo">
            <VizSensor 
              partialVisibility={true} 
              onChange={(isVisible) => {
                isVisible ? this.PortBJVideoRef.current.play() : this.PortBJVideoRef.current.pause()
              }}
            >
            <Plx parallaxData={ VideoBlock100BParallaxData } className="VideoBlockVideoWrapper">
               <video ref={this.PortBJVideoRef} autoPlay controls controlsList="nofullscreen nodownload" muted playsInline preload="metadata" poster={PortBJPoster}><source src={PortBJVideo} type="video/mp4"/></video>
            </Plx>              
            </VizSensor>
            </div>

            <div className="VideoBlockParagraph">        
            <div className="VideoBlockText">        
              <h3>Bennett Jones LLP</h3>
              <p>Legal Services</p>
            </div>
          </div>              
        </div>

        {/*Video Block 100*/}
        <div className="ContentBlock VideoBlock100B">
            <div className="VideoBlockVideo">
            <VizSensor 
              partialVisibility={true} 
              onChange={(isVisible) => {
                isVisible ? this.PortCWVideoRef.current.play() : this.PortCWVideoRef.current.pause()
              }}
            >              
            <Plx parallaxData={ VideoBlock100BParallaxData } className="VideoBlockVideoWrapper">
            <div className="VideoBlockVideo ">
                  <video ref={this.PortCWVideoRef} autoPlay controls controlsList="nofullscreen nodownload" muted playsInline preload="metadata" poster={PortCWPoster}><source src={PortCWVideo} type="video/mp4"/></video>
                  </div>
            </Plx>
            </VizSensor>
            </div>

            <div className="VideoBlockParagraph">        
            <div className="VideoBlockText">        
                <h3>Chartwell</h3>
                <p>Retirement Residences</p>
              </div>
            </div>              
        </div>

 {/*Video Block 100*/}
 <div className="ContentBlock VideoBlock100B SecondBlock" id="SecondBlock">
            <div className="VideoBlockVideo">
            <VizSensor 
              partialVisibility={true} 
              onChange={(isVisible) => {
                isVisible ? this.PortGnwVideoRef.current.play() : this.PortGnwVideoRef.current.pause()
              }}
            >              
            <Plx parallaxData={ VideoBlock100BParallaxData } className="VideoBlockVideoWrapper">
            <div className="VideoBlockVideo ">
                  <video ref={this.PortGnwVideoRef} autoPlay controls controlsList="nofullscreen nodownload" muted playsInline preload="metadata" poster={PortGNWPoster}><source src={PortGNWVideo} type="video/mp4"/></video>
                  </div>
            </Plx>
            </VizSensor>
            </div>

            <div className="VideoBlockParagraph"> 
            <div className="VideoBlockText">        
            <h3>Genworth Canada</h3>
                <p>Financial Services</p>
              </div>
            </div>   

        </div>


        {/*Video Block 100*/}
        <div className="ContentBlock VideoBlock100B">
            <div className="VideoBlockVideo">
            <VizSensor 
              partialVisibility={true} 
              onChange={(isVisible) => {
                isVisible ? this.PortStreetVideoRef.current.play() : this.PortStreetVideoRef.current.pause()
              }}
            >              
            <Plx parallaxData={ VideoBlock100BParallaxData } className="VideoBlockVideoWrapper">
              <div className="VideoBlockVideo ">
              <video ref={this.PortStreetVideoRef} autoPlay controls controlsList="nofullscreen nodownload" muted playsInline preload="metadata" poster={PortStreetPoster}><source src={PortStreetVideo} type="video/mp4"/></video>
              </div>
            </Plx>              
            </VizSensor>
            </div>

              <div className="VideoBlockParagraph">        
              <div className="VideoBlockText">        
              <h3>Street Capital Bank</h3>
              <p>Financial Services</p>
              </div>
              </div>              

        </div>

        {/*Video Block 100*/}
        <div className="ContentBlock VideoBlock100B">
        <div className="VideoBlockVideo">
            <VizSensor 
              partialVisibility={true} 
              onChange={(isVisible) => {
                isVisible ? this.PortSBVideoRef.current.play() : this.PortSBVideoRef.current.pause()
              }}
            >
            <Plx parallaxData={ VideoBlock100BParallaxData } className="VideoBlockVideoWrapper">
                  <div className="VideoBlockVideo ">
                  <video ref={this.PortSBVideoRef} autoPlay controls controlsList="nofullscreen nodownload" muted playsInline preload="metadata" poster={PortSBPoster}><source src={PortSBVideo} type="video/mp4"/></video>
                  </div>
            </Plx>              
            </VizSensor>
        </div>
             <div className="VideoBlockParagraph">        
             <div className="VideoBlockText">        
              <h3>Starbucks</h3>
              </div>

              </div>              

        </div>



        {/*Video Block 100*/}
        <div className="ContentBlock VideoBlock100B">
        <div className="VideoBlockVideo">
            <VizSensor 
              partialVisibility={true} 
              onChange={(isVisible) => {
                isVisible ? this.PortCBBVideoRef.current.play() : this.PortCBBVideoRef.current.pause()
              }}
            >
            <Plx parallaxData={ VideoBlock100BParallaxData } className="VideoBlockVideoWrapper">
                  <div className="VideoBlockVideo ">
                  <video ref={this.PortCBBVideoRef} autoPlay controls controlsList="nofullscreen nodownload" muted playsInline preload="metadata" poster={PortCBBPoster}><source src={PortCBBVideo} type="video/mp4"/></video>
                  </div>
            </Plx>                
            </VizSensor>
        </div>
              <div className="VideoBlockParagraph">        
              <div className="VideoBlockText">        
                <h3>Cassels LLP</h3>
                <p>Legal Services</p>
              </div>

              </div>              

        </div>


        {/*Video Block 100*/}
        <div className="ContentBlock VideoBlock100B">
        <div className="VideoBlockVideo">
            <VizSensor 
              partialVisibility={true} 
              onChange={(isVisible) => {
                isVisible ? this.PortSKVideoRef.current.play() : this.PortSKVideoRef.current.pause()
              }}
            >
            <Plx parallaxData={ VideoBlock100BParallaxData } className="VideoBlockVideoWrapper">
                  <div className="VideoBlockVideo ">
                  <video ref={this.PortSKVideoRef} autoPlay controls controlsList="nofullscreen nodownload" muted playsInline preload="metadata" poster={PortSKPoster}><source src={PortSKVideo} type="video/mp4"/></video>
                  </div>
            </Plx>              
            </VizSensor>
        </div>
            <div className="VideoBlockParagraph">        
            <div className="VideoBlockText">        
              <h3>SickKids</h3>
                <p>Hospital and Research</p>
            </div>
              </div>              

        </div>

 {/*Video Block 100*/}
 <div className="ContentBlock VideoBlock100B">
 <div className="VideoBlockVideo">
            <VizSensor 
              partialVisibility={true} 
              onChange={(isVisible) => {
                isVisible ? this.PortHFHVideoRef.current.play() : this.PortHFHVideoRef.current.pause()
              }}
            >
            <Plx parallaxData={ VideoBlock100BParallaxData }  className="VideoBlockVideoWrapper">
                  <div className="VideoBlockVideo ">
                    <video ref={this.PortHFHVideoRef}  autoPlay controls controlsList="nofullscreen nodownload" muted playsInline preload="metadata" poster={PortHFHPoster}><source src={PortHFHVideo} type="video/mp4"/></video>
                  </div>
            </Plx>              
            </VizSensor>
              </div>
              <div className="VideoBlockParagraph">        
              <div className="VideoBlockText">        
              <h3>Habitat For Humanity Canada</h3>
              <p>Not for Profit</p>
              </div>
               </div>              

        </div>


        {/*Video Block 100*/}
        <div className="ContentBlock VideoBlock100B">
        <div className="VideoBlockVideo">

            <Plx parallaxData={ VideoBlock100BParallaxData }  className="VideoBlockVideoWrapper">
              
              <Carousel 
                                    pauseOnHover	= {false}              
                                    autoplay = {true}
                                    transitionMode = 'fade'
                                    slidesToShow={1}
                                    wrapAround={true}
                                    withoutControls={true}
                                    speed={SLIDER_SPEED}
                                    autoplayInterval = {SLIDER_AUTOPLAYINTERVAL}	
                                    heightMode="max"
                                    className="image-carousel"
                                >
                                {SLIDES_STAGEWEST.map(function(d, idx){
                                    return (<img key={idx} src={d.path} alt={d.alt}></img>)
                                })}

                                </Carousel>  
              
              </Plx>            
                
              
              </div>
              <div className="VideoBlockParagraph">        
              <div className="VideoBlockText">        
                <h3>Stagewest Hospitality</h3>
                <p>Hospitality and Gaming</p>
              </div>              
              </div>              
        </div>
        </>
      )
    }
}